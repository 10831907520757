<template>
  <div class="setting_form  ">
    <b-modal modal-class="modal_basic modalAddTag sm-modal" id="modalAddTag" hide-footer hide-header>
      <img @click="$bvModal.hide('modalAddTag')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>Add Tag</h3>
        </div>

        <!--        <form class="  clear">-->
        <div class="row">
          <div class="col-md-12 pl-0 pr-1">
            <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
                 :class="{'input-field-error': validations.tag_name || validations.tag_name_length}">
              <div class="content">
                <div class="url">Tag Name</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="text" v-model="add_tag_name"
                           @keyup.enter="storeTagName()"
                           placeholder="e.g marketing" data-cy="tag-name">

                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.tag_name">{{ messages.tag_name }}</span>
            <span class="input-error" v-else-if="validations.tag_name_length">{{ messages.tag_name_length }}</span>
          </div>
        </div>
        <!--        </form>-->

        <div class=" btn_block text-left">
          <button @click.prevent="storeTagName()" :disabled="addTagLoader"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>Add</span>
            <clip-loader v-if="addTagLoader" :size="'14px'" :color="'#ffffff'"></clip-loader>
          </button>

          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('modalAddTag')"><span>Close</span>
          </button>
        </div>

      </div>
    </b-modal>
    <div class="white_box ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Tags</h2>

          </div>
          <div class="right ml-auto">
            <template>
              <button @click.prevent="addTagModal"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                <span>Add Tag</span>
                <span class="icon_right">
                  <i class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                </span>
              </button>
            </template>

            <div class="tag_option">
              <div class="inner">
                <ul class="bottom_bar" v-if="getTagSelection.length > 0">
                  <li class="more_option" v-if="getTagSelection.length == 1">
                    <div @click="toggleTooltip($event)" class="content"
                         v-tooltip.top-center="'Edit'">
                      <i class="option_icon fal fa-tag"></i>
                    </div>
                    <div class="marge_tooltip">
                      <div class="inner_box">
                        <div class="editor_input">
                          <input placeholder="Enter new name..." @keyup.enter="editTags()"
                                 v-model="getTag.edit" type="text" data-cy="edit-name">
                          <button :disabled="addTagLoader" class="btn" @click.prevent="editTags()">
                            <i v-if="!addTagLoader" class="fal fa-check"></i>
                            <clip-loader v-if="addTagLoader" :size="'11px'" :color="'#45A2FF'"></clip-loader>
                          </button>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li class="more_option" v-if="getTagSelection.length > 1">
                    <div @click="toggleTooltip($event)" class="content"
                         v-tooltip.top-center="'Merge'">
                      <i class="option_icon fal fa-code-fork"></i>
                    </div>
                    <div class="marge_tooltip">
                      <div class="inner_box">
                        <div class="editor_input">
                          <input placeholder="Enter new name..." @keyup.enter="mergeTags()"
                                 v-model="getTag.merge" type="text">
                          <button class="btn" @click.prevent="mergeTags()">
                            <i class="fal fa-check"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="remove_option" v-if="">
                    <div class="content" v-tooltip.top-center="'Delete'"
                         @click.prevent="deleteTags()">
                      <i class="option_icon fal fa-trash"></i>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
            <button v-if="false" class="btn---cta btn-blue with-shadow btn-round   btn-bold">
              <span>Add Tag</span>
              <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
            </button>

          </div>

        </div>
        <div class="box_content">

          <div class="tags_tab_main">
            <clip-loader v-if="fetchLoader" :size="'22px'" :color="'#45A2FF'"></clip-loader>
            <ul class="text-center" v-else-if="getTagList.length == 0">
              You have not created any tags yet.
            </ul>
            <ul class="tag_list" v-else>

              <template>
                <li class="" v-for="(tags,index) in getTagList"
                    @click.prevent="selectTagForAction(tags._id)"
                    :class="{'active' : getTagSelection.indexOf(tags._id) >= 0}"
                    v-tooltip.top-center="tags.tag">
                                    <span class="d-flex align-items-center">
                                           <span
                                             class="d-flex align-items-center justify-content-center number">{{
                                               index + 1
                                             }}</span>
                                           <span class="text">{{ tags.tag }}</span>
                                    </span>

                </li>
              </template>

            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tagTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  data () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      add_tag_name: null,
      validations: {
        tag_name: false,
        tag_name_length: false
      },
      messages: {
        tag_name: 'Tag name is required.',
        tag_name_length: 'The tag should not be greater than 35 characters.'
      },
      addTagLoader: false,
      fetchLoader: false
    }
  },

  async created () {
    this.getSiteDetails
    this.fetchLoader = true
    await this.fetchTags()
    this.fetchLoader = false
  },
  computed: {
    ...mapGetters([
      'getTagList',
      'getTagSelection',
      'getTag',
      'getGuidedTour', 'getTeam', 'getBrands', 'getUserLoader', 'getDomains', 'getTeamMember'
    ])

  },
  methods: {
    ...mapMutations([tagTypes.SET_TAG_SELECTION]),
    ...mapActions([
      'storeTag',
      'deleteTags',
      'fetchTags',
      'mergeTags',
      'setAllBrandsFetchStatus',
      'fetchTags'
    ]),
    toggleTooltip (e) {
      this.getTag.edit = ''
      $(e.currentTarget.nextElementSibling).toggleClass('animate_it')
    },
    selectTagForAction (id) {
      let selectedTags = this.getTagSelection
      let index = selectedTags.indexOf(id)
      if (index >= 0) {
        selectedTags.splice(index, 1)
      } else {
        selectedTags = selectedTags.concat(id)
      }
      this[tagTypes.SET_TAG_SELECTION](selectedTags)
    },

    async editTags () {
      let id = this.getTagSelection[0]
      let value = this.getTag.edit
      if (value && (value.trim()).length > 0) {
        this.addTagLoader = true
        await this.storeTag({ id: id, name: value, type: 'edit' })
        this.addTagLoader = false
        $('.marge_tooltip').removeClass('animate_it')
      } else {
        this.alertMessage('Please enter tag name.', 'error')
      }
    },
    async storeTagName () {
      this.validations.tag_name = this.requiredCheck(this.add_tag_name)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.addTagLoader = true
        await this.storeTag({ name: this.add_tag_name }).then(
          res => {
            if (res) {
              this.$bvModal.hide('modalAddTag')
              this.add_tag_name = ''
              this.$store.dispatch('toastNotification', { message: 'Tag name added successfully.' })
              this.fetchTags()
            }
            this.addTagLoader = false
          }
        )
      }
    },
    addTagModal () {
      this.add_tag_name = ''
      this.$bvModal.show('modalAddTag')
    }
  },
  watch: {
    'add_tag_name' (value) {
      if (value && value.length > 0) {
        this.validations.tag_name = false
        this.validations.tag_name_length = !this.maxLength(value, 35)
      }
    }
  }
})
</script>

<style lang="less">
.sm-modal {
  .modal-dialog {
    max-width: 400px !important;
  }
}
</style>
